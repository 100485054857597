import {
  FormControl,
  InputBase,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Typography,
} from '@mui/material'
import React from 'react'
import { UpdateFieldsType } from '../types'
import customTheme, { backgroundColor } from '../config/stylesheet'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const BootstrapInput = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: backgroundColor,
    borderBottom: `1px solid ${customTheme.palette.primary.main}`,
    fontSize: 16,
    padding: '10px 26px 10px 0px',
    transition: customTheme.transitions.create(['border-color', 'box-shadow']),
  },
  '& .MuiSelect-icon': {
    color: 'white',
    width: 'auto',
    height: '100%',
    top: '0px',
  },
}))

type SelectElement = {
  value: string
  label: string
}

interface SelectDropdownProps {
  elements: SelectElement[]
  dataKey: string
  updateFields: UpdateFieldsType
  selectedValue: string
  label: string
}

const SelectDropdown = (props: SelectDropdownProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value) {
      props.updateFields({ [props.dataKey]: event.target.value })
    }
  }

  return (
    <FormControl variant="standard" sx={{ width: '100%' }}>
      <Typography color="primary" textAlign="left" fontFamily="Open Sans" fontWeight={400} fontSize="12px">
        {props.label}
      </Typography>
      <Select
        onChange={handleChange}
        value={props.selectedValue}
        style={{ width: '100%', color: 'white', textAlign: 'left' }}
        input={<BootstrapInput />}
        IconComponent={KeyboardArrowDownIcon}
      >
        {props.elements.map((element, index) => (
          <MenuItem value={element.value} key={index}>
            {element.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectDropdown
