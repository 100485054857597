import { ReactElement, useState } from 'react'

const useMultiStepForm = (steps: ReactElement[]) => {
  const [currentStep, setStep] = useState(0)

  const goNextStep = () => {
    setStep(currentStep + 1)
  }

  const isFirstStep: boolean = currentStep == 0

  const goPreviousStep = () => {
    isFirstStep ? setStep(0) : setStep(currentStep - 1)
  }

  return {
    currentStep,
    step: steps[currentStep - 1],
    isFirstStep,
    goNextStep,
    goPreviousStep,
    goFirstStep: () => setStep(0),
  }
}

export default useMultiStepForm
