import { Box, styled } from '@mui/material'
import customTheme from '../../config/stylesheet'

const StyledOption = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '& .MuiAutocomplete-option': {
    paddingLeft: '6px',
  },

  borderBottom: `0.5px solid ${customTheme.palette.primary.main}`,
})

export default StyledOption
