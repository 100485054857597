import SelectGroup from '../components/SelectGroup'
import Title from '../components/Title'
import { UpdateFieldsType } from '../types'

const usagesOptions = [
  { label: 'Essence', value: 'Essence' },
  { label: 'Cigarettes', value: 'Cigarettes' },
  { label: 'Peinture', value: 'Peinture' },
  { label: 'Engrais / Pesticides', value: 'Engrais / Pesticides' },
]

interface UsageProps {
  updateFields: UpdateFieldsType
  inContactProducts: string[]
}

const UsageSecondPage = ({ updateFields, inContactProducts }: UsageProps) => {
  return (
    <div>
      <Title id="usage-products" />
      <SelectGroup
        elements={usagesOptions}
        multiple={true}
        dataKey="inContactProducts"
        updateFields={updateFields}
        selectedValues={inContactProducts}
      />
    </div>
  )
}

export default UsageSecondPage
