import Typography from '@mui/material/Typography'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import FormButton from '../components/FormButton'
import Title from '../components/Title'
import { Box } from '@mui/material'
import customTheme from '../config/stylesheet'
import { useTranslation } from 'react-i18next'
import WhiteTextBox from '../components/WhiteTextBox.style'

interface HomePageProps {
  startForm: () => void
}

const REPORT_LINK =
  'https://docs.google.com/forms/d/1x1YCZgLQoq7uWz9v5zt21Qj0T4Kqkbu-JDBGfwWck_o/edit?usp=drivesdk'

const FULL_REPORT_LINK = 'https://surfrider.eu/nos-missions/qualite-eau-sante-usagers/'

const HomePage = (props: HomePageProps) => {
  const { t } = useTranslation()

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '-8%',
        }}
      >
        <img
          src="/assets/SurfPicture.jpg"
          style={{
            width: '55vw',
            maxWidth: '400px',
          }}
          alt={'a man surfing a wave'}
        />
      </div>
      <div style={{ marginTop: '-8vh', zIndex: '1', fontWeight: '400' }}>
        <Title id="home" />
      </div>

      <FormButton
        type="startForm"
        onClick={() => {
          props.startForm()
        }}
      />

      <Typography
        marginTop="50px"
        fontFamily="Open Sans"
        color="white"
        fontSize="24px"
        whiteSpace="pre-wrap"
        textAlign="left"
      >
        {t('home.first-question')}
      </Typography>
      <div style={{ display: 'flex', marginTop: '8px', alignItems: 'center' }}>
        <Box
          sx={{
            backgroundColor: 'white',
            boxShadow: `3px 4px ${customTheme.palette.primary.light}`,
            height: '26px',
            width: '26px',
            padding: '16px',
            borderRadius: '60px',
            textAlign: 'left',
          }}
        >
          <Typography sx={{ fontSize: '24px', marginTop: '-3px' }}>🥴</Typography>
        </Box>
        <a style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }} href={REPORT_LINK}>
          <Typography
            color="secondary"
            fontFamily="Open Sans"
            fontWeight={700}
            fontSize="18px"
            marginLeft="16px"
          >
            {t('home.report')}
          </Typography>

          <ArrowForwardIcon
            sx={{
              marginLeft: '11px',
              height: '24px',
              color: `${customTheme.palette.secondary.light}`,
            }}
          />
        </a>
      </div>
      <Typography color="white" fontSize="16px" textAlign="left" marginTop="40px">
        {t('home.information-number')}
      </Typography>

      <Typography
        color="white"
        fontSize="32px"
        fontFamily="Titan One, cursive"
        lineHeight={0.8}
        textAlign="left"
        marginTop="4px"
        marginBottom="16px"
      >
        {t('home.to-know')}
      </Typography>

      <WhiteTextBox>
        <Typography sx={{ fontWeight: '600' }}>{t('home.first-paragraph.bold-text')}</Typography>
        <Typography>{t('home.first-paragraph.text')}</Typography>
      </WhiteTextBox>

      <Box
        sx={{
          display: 'flex',
        }}
      >
        <WhiteTextBox>
          <Typography sx={{ fontWeight: '600' }}>{t('home.second-paragraph.bold-text')} </Typography>
          <Typography>{t('home.second-paragraph.text')}</Typography>
        </WhiteTextBox>

        <WhiteTextBox
          sx={{
            marginLeft: '20px',
            height: 'fit-content',
          }}
        >
          <Typography>{t('home.third-paragraph.text')}</Typography>
          <Typography sx={{ fontWeight: '600' }}>{t('home.third-paragraph.bold-text')}</Typography>
        </WhiteTextBox>
      </Box>

      <a
        style={{
          textDecoration: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '16px',
        }}
        href={FULL_REPORT_LINK}
      >
        <Typography
          color="secondary"
          fontFamily="Open Sans"
          fontWeight={700}
          fontSize="18px"
          marginLeft="16px"
        >
          {t('home.full_report')}
        </Typography>

        <ArrowForwardIcon
          sx={{
            marginLeft: '11px',
            height: '24px',
            color: `${customTheme.palette.secondary.light}`,
          }}
        />
      </a>
      <a href="https://surfrider.eu/">
        <img
          src="/assets/SurfriderLogo.png"
          width="140px"
          style={{ display: 'block', margin: '48px auto 0 auto' }}
          alt={'the surfrider association logo'}
        />
      </a>
    </>
  )
}

export default HomePage
