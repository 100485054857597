import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import customTheme from '../config/stylesheet'

const WhiteTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: `${customTheme.palette.primary.main}`,
    border: 'none',
  },

  '& .MuiInputLabel-root': {
    color: `${customTheme.palette.primary.main}`,
  },

  '& .MuiIconButton-root': {
    color: `${customTheme.palette.primary.main}`,
  },

  '& .MuiOutlinedInput-root': {
    color: `${customTheme.palette.primary.main}`,
    '& fieldset': {
      border: 'none',
    },
  },
})

export default WhiteTextField
