import Title from '../components/Title'
import { UpdateFieldsType } from '../types'
import SelectDropdown from '../components/SelectDropdown'
import { useTranslation } from 'react-i18next'
import GroupedInputPickers from '../components/GroupedInputPickers'

interface ContextChoiceProps {
  updateFields: UpdateFieldsType
  date: string
  time: string
  swimmingDuration: string
}

const swimmingDurationOptions = [
  { label: '30min', value: '30min' },
  { label: '1h', value: '1h' },
  { label: '1h30', value: '1h30' },
  { label: '2h', value: '2h' },
  { label: '2h30', value: '2h30' },
  { label: '3h ou +', value: '3h ou +' },
]

const ContextPage = ({ updateFields, date, time, swimmingDuration }: ContextChoiceProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Title id="context" />
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', gap: '56px' }}>
        <GroupedInputPickers updateFields={updateFields} date={date} time={time} />
        <SelectDropdown
          elements={swimmingDurationOptions}
          dataKey="swimmingDuration"
          updateFields={updateFields}
          selectedValue={swimmingDuration}
          label={t('context.swimmingDuration')}
        />
      </div>
    </>
  )
}

export default ContextPage
