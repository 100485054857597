import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import React from 'react'
import { useTranslation } from 'react-i18next'
import spotList, { SpotOptionType } from '../config/spotsList'
import WhiteTextField from './TextField.style'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import StyledPaper from './SearchBar/StyledPaper.style'
import StyledOption from './SearchBar/StyledOption.style'
import customTheme from '../config/stylesheet'
import { UpdateFieldsType } from '../types'

const filter = createFilterOptions<SpotOptionType>()

type SearchBarProps = {
  updateFields: UpdateFieldsType
  searchedElement?: string
}

const SearchBar = ({ updateFields, searchedElement }: SearchBarProps) => {
  const { t } = useTranslation()
  const [value, setValue] = React.useState<SpotOptionType | null>(
    searchedElement
      ? {
          completeSpot: searchedElement,
        }
      : null
  )

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({
            completeSpot: newValue,
          })
          updateFields({ spot: newValue })
        } else if (newValue && newValue.inputValue) {
          setValue({
            completeSpot: newValue.inputValue,
          })
          updateFields({
            spot: newValue.inputValue,
          })
        } else {
          setValue(newValue)
          newValue && updateFields({ spot: newValue.completeSpot })
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params).slice(0, 3)

        const { inputValue } = params
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.completeSpot)
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            completeSpot: `Ajouter le spot : "${inputValue}"`,
          })
        }

        return filtered
      }}
      selectOnFocus
      clearOnBlur
      openOnFocus={true}
      handleHomeEndKeys
      id="spot-choice"
      options={spotList}
      PaperComponent={StyledPaper}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        }
        if (option.inputValue) {
          return option.inputValue
        }
        return option.completeSpot
      }}
      renderOption={(props, option) => (
        <StyledOption key={option.completeSpot}>
          <PlaceOutlinedIcon sx={{ height: '14px' }} />
          <li {...props}>{option.completeSpot}</li>
        </StyledOption>
      )}
      sx={{ width: '100%' }}
      freeSolo
      renderInput={(params) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderBottom: `0.5px solid ${customTheme.palette.primary.main}`,
          }}
        >
          <img src="/assets/WhiteSearchIcon.svg" alt={'a search icon'} />
          <WhiteTextField {...params} size="medium" label={t('spot.searchbar.placeholder') as string} />
        </Box>
      )}
    />
  )
}

export default SearchBar
