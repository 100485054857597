import { DatePicker, DesktopDatePicker, MobileTimePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { UpdateFieldsType } from '../types'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import styled, { css } from 'styled-components'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'

type GroupedInputPickersProps = {
  updateFields: UpdateFieldsType
  date: string
  time: string
}

const inputPickerStyle = css({
  '.MuiInputBase-root': {
    color: 'white',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: '0.5px solid white',
    borderRadius: 0,
  },
  '.MuiFormControl-root': {
    width: '100%',
  },
  '.MuiFormLabel-root': {
    color: 'white',
    left: '-14px',
  },
  '.MuiSvgIcon-root': {
    color: 'white',
  },
  '.MuiInputBase-input': {
    paddingLeft: '0px',
  },
})

const DatePickerStyled: typeof DatePicker = styled(DesktopDatePicker)`
  ${inputPickerStyle}
`

const TimePickerStyled = styled(MobileTimePicker)`
  ${inputPickerStyle}
`

const GroupedInputPickers = ({ updateFields, date, time }: GroupedInputPickersProps) => {
  const [timeValue] = useState(new Date())
  const [dateValue] = useState(new Date())

  useEffect(() => {
    date != '' ? updateFields({ date: date }) : updateFields({ date: dateValue.toDateString() })
    time != '' ? updateFields({ time: time }) : updateFields({ time: format(timeValue, 'HH:mm') })
  }, [dateValue, timeValue])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} color="white">
      <DatePickerStyled
        maxDate={new Date()}
        onChange={(value) => value && updateFields({ date: value.toDateString() })}
        format="dd / MM / yyyy"
        label="Indique la date"
        value={date ? new Date(date) : dateValue}
        slots={{ openPickerIcon: CalendarTodayOutlinedIcon }}
      />
      <TimePickerStyled
        ampm={false}
        label="L'heure de début"
        onChange={(value) => value && updateFields({ time: format(value as Date, 'HH:mm') })}
        value={time ? new Date(`2000-01-01T${time}:00`) : timeValue}
      />
    </LocalizationProvider>
  )
}

export default GroupedInputPickers
