import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

interface TitleProps {
  id: string
}

const Title = (props: TitleProps) => {
  const { t } = useTranslation()
  const { id } = props
  return (
    <div style={{ marginBottom: '24px' }}>
      <Typography color="secondary" textAlign="left" fontFamily="Open Sans" fontWeight={400} fontSize="24px">
        {t(`${id}.title.prefix`)}
      </Typography>
      <Typography
        color="primary"
        fontSize="40px"
        fontFamily="Titan One, cursive"
        lineHeight={0.8}
        fontWeight={400}
        textAlign="left"
      >
        {t(`${id}.title.subject`)}
      </Typography>
      <Typography
        color="primary"
        textAlign="left"
        fontFamily="Open Sans"
        fontWeight={400}
        fontSize="17px"
        marginTop="32px"
      >
        {t(`${id}.title.subtitle`)}
      </Typography>
    </div>
  )
}

export default Title
