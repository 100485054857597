import SelectGroup from '../components/SelectGroup'
import Title from '../components/Title'
import { UpdateFieldsType } from '../types'

const waterQualityOptions = [
  { label: 'Bleue claire', value: 'Bleue claire' },
  { label: 'Verte', value: 'Verte' },
  { label: 'Marron', value: 'Marron' },
]

interface WaterQualityProps {
  updateFields: UpdateFieldsType
  waterColor: string
}
const WaterQualityPage = ({ updateFields, waterColor }: WaterQualityProps) => {
  return (
    <div>
      <Title id="water" />
      <SelectGroup
        elements={waterQualityOptions}
        multiple={false}
        dataKey="waterColor"
        updateFields={updateFields}
        selectedValues={[waterColor]}
      />
    </div>
  )
}

export default WaterQualityPage
