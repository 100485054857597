import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import frTranslations from './translations/fr.json'

const resources = {
  en: {
    translation: frTranslations, // To be updated to enTranslations when available
  },
  fr: {
    translation: frTranslations,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'fr',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
