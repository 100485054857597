import { styled } from '@mui/material'
import Paper from '@mui/material/Paper'
import customTheme, { backgroundColor } from '../../config/stylesheet'

const StyledPaper = styled(Paper)({
  color: `${customTheme.palette.primary.main}`,
  marginTop: '10px',
  backgroundColor: `${backgroundColor}`,
  boxShadow: 'none',
  marginLeft: '-35px',
  '& .MuiBox-root:active': {
    color: `${customTheme.palette.secondary.main}`,
  },
})

export default StyledPaper
