import SelectGroup from '../components/SelectGroup'
import Title from '../components/Title'
import { UpdateFieldsType } from '../types'

const usagesOptions = [
  { label: 'Crème solaire', value: 'Crème solaire' },
  { label: 'Parfum ou déodorant', value: 'Parfum ou déodorant' },
  { label: 'Crème hydratante', value: 'Crème hydratante' },
  { label: 'Maquillage', value: 'Maquillage' },
]

interface UsageProps {
  updateFields: UpdateFieldsType
  usedProducts: string[]
}

const UsageFirstPage = ({ updateFields, usedProducts }: UsageProps) => {
  return (
    <div>
      <Title id="usage-cosmetics" />
      <SelectGroup
        elements={usagesOptions}
        multiple={true}
        dataKey="usedProducts"
        updateFields={updateFields}
        selectedValues={usedProducts}
      />
    </div>
  )
}

export default UsageFirstPage
