import SelectGroup from '../components/SelectGroup'
import Title from '../components/Title'
import { UpdateFieldsType } from '../types'

const crowdOptions = [
  { label: '5 ou moins', value: '5 ou moins' },
  { label: 'Entre 5 et 20', value: 'Entre 5 et 20' },
  { label: 'Entre 20 et 50', value: 'Entre 20 et 50' },
  { label: 'Plus de 50', value: 'Plus de 50' },
]

interface CrowdProps {
  updateFields: UpdateFieldsType
  numberOfPeopleRange: string
}

const CrowdPage = ({ updateFields, numberOfPeopleRange }: CrowdProps) => {
  return (
    <div>
      <Title id="crowd" />
      <SelectGroup
        elements={crowdOptions}
        multiple={false}
        dataKey="numberOfPeopleRange"
        updateFields={updateFields}
        selectedValues={[numberOfPeopleRange]}
      />
    </div>
  )
}

export default CrowdPage
