import SearchBar from '../components/SearchBar'
import Title from '../components/Title'
import { UpdateFieldsType } from '../types'

interface SpotChoiceProps {
  updateFields: UpdateFieldsType
  spot: string
}

const SpotChoicePage = ({ updateFields, spot }: SpotChoiceProps) => {
  return (
    <div>
      <Title id="spot" />
      <SearchBar updateFields={updateFields} searchedElement={spot} />
    </div>
  )
}

export default SpotChoicePage
