import Box from '@mui/material/Box'
import ContextPage from '../pages/ContextPage'
import CrowdPage from '../pages/CrowdPage'
import SpotChoicePage from '../pages/SpotChoicePage'
import UsageFirstPage from '../pages/UsageFirstPage'
import WaterQualityPage from '../pages/WaterQualityPage'
import FormButton from './FormButton'
import ProgressBar from './ProgressBar'
import UsageSecondPage from '../pages/UsageSecondPage'
import useMultiStepForm from '../useMultiStepForm'
import HomePage from '../pages/HomePage'
import { FormEvent, useEffect, useState } from 'react'
import StyledForm from './Form.style'
import ThankYouPage from '../pages/ThankYouPage'

type Inputs = {
  spot: string
  date: string
  time: string
  swimmingDuration: string
  waterColor: string
  numberOfPeopleRange: string
  usedProducts: string[]
  inContactProducts: string[]
}

const Layout = () => {
  const totalNumberOfSteps = 6
  const THANK_PAGE_DURATION = 3000

  const INITIAL_DATA: Inputs = {
    spot: '',
    date: '',
    time: '',
    swimmingDuration: '',
    waterColor: '',
    numberOfPeopleRange: '',
    usedProducts: [],
    inContactProducts: [],
  }
  const [data, setData] = useState<Inputs>(INITIAL_DATA)

  const [disable, setDisable] = useState<boolean>(true)

  const [ip, setIP] = useState('')

  useEffect(() => {
    fetch('https://api.ipify.org/?format=json')
      .then((res) => res.json())
      .then((data) => {
        setIP(data.ip)
      })
  }, [])

  // @hack: Numa : should not be checked using newData but use react-hook form instead
  const checkButtonActivation = (newData: Partial<Inputs>, currentStep: number) => {
    switch (currentStep) {
      case 1:
        newData.spot ? setDisable(false) : setDisable(true)
        break
      case 2:
        newData.swimmingDuration ? setDisable(false) : setDisable(true)
        break
      case 3:
        newData.waterColor ? setDisable(false) : setDisable(true)
        break
      case 4:
        newData.numberOfPeopleRange ? setDisable(false) : setDisable(true)
        break
      case 5:
        newData.usedProducts && newData.usedProducts.length > 0 ? setDisable(false) : setDisable(true)
        break
      case 6:
        newData.inContactProducts && newData.inContactProducts.length > 0
          ? setDisable(false)
          : setDisable(true)
        break
    }
  }

  const updateFields = (fields: Partial<Inputs>) => {
    setData((prev) => {
      const newData = { ...prev, ...fields }
      checkButtonActivation(newData, currentStep)
      return newData
    })
  }

  const { currentStep, goNextStep, goPreviousStep, step, isFirstStep, goFirstStep } = useMultiStepForm([
    <SpotChoicePage key="spot" {...data} updateFields={updateFields} />,
    <ContextPage key="context" {...data} updateFields={updateFields} />,
    <WaterQualityPage key="water" {...data} updateFields={updateFields} />,
    <CrowdPage key="crowd" {...data} updateFields={updateFields} />,
    <UsageFirstPage key="usageFirst" {...data} updateFields={updateFields} />,
    <UsageSecondPage key="usageSecond" {...data} updateFields={updateFields} />,
  ])

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    goNextStep()
  }

  const isLastStep = currentStep === totalNumberOfSteps
  const isThankYouPage = currentStep > totalNumberOfSteps

  const showThankYouPage = () => {
    goNextStep()
    setTimeout(() => {
      setData(INITIAL_DATA)
      goFirstStep()
    }, THANK_PAGE_DURATION)
  }

  const PATH_URL = 'https://writesurfriderdata.azurewebsites.net/api/sendData'

  const postData = async (data = {}) => {
    await fetch(PATH_URL, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...data, ip }),
    })
      .then((response) => {
        return response.json()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const BottomButton = () => {
    return isLastStep ? (
      <FormButton
        type="submit"
        onClick={() => {
          showThankYouPage()
          postData(data)
        }}
        disabled={disable}
      />
    ) : (
      <FormButton
        disabled={disable}
        type="nextStep"
        onClick={() => {
          checkButtonActivation(data, currentStep + 1)
          goNextStep()
        }}
      />
    )
  }

  return (
    <>
      {isFirstStep ? (
        <HomePage startForm={() => goNextStep()} />
      ) : isThankYouPage ? (
        <ThankYouPage />
      ) : (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
            <img
              src="/assets/ArrowCircleLeftOutlined.svg"
              onClick={() => {
                goPreviousStep()
                checkButtonActivation(data, currentStep - 1)
              }}
              alt={'an arrow inside a circle'}
            />
            <ProgressBar step={currentStep} totalSteps={totalNumberOfSteps} />
          </Box>
          <StyledForm onSubmit={onSubmit}>
            {step}
            <BottomButton />
          </StyledForm>
        </>
      )}
    </>
  )
}

export default Layout
