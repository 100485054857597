import { styled } from '@mui/material/styles'
import customTheme from '../config/stylesheet'
import Box from '@mui/material/Box'

const WhiteTextBox = styled(Box)({
  backgroundColor: 'white',
  marginBottom: '20px',
  padding: '16px',
  flexBasis: '100px',
  flexGrow: 1,
  boxShadow: `2px 3px ${customTheme.palette.primary.light}`,
  borderRadius: '8px',
  textAlign: 'left',

  '& .MuiTypography-root': {
    fontFamily: 'Open Sans',
  },
})

export default WhiteTextBox
