import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { styled } from '@mui/material/styles'
import customTheme, { darkBlue } from '../config/stylesheet'
import React, { useState } from 'react'
import { UpdateFieldsType } from '../types'

interface SelectChoiceProps {
  value: string
  label: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isChecked: boolean
}

interface NothingChoiceProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isChecked: boolean
}

const CheckBoxChoice = (props: SelectChoiceProps) => {
  const { value, label, onChange, isChecked } = props

  return (
    <FormControlLabelStyled
      value={value}
      control={<Checkbox onChange={onChange} checked={isChecked} />}
      label={label}
      sx={{ margin: 'auto' }}
    />
  )
}

const RadioChoice = (props: SelectChoiceProps) => {
  const { value, label, onChange, isChecked } = props

  return (
    <FormControlLabelStyled
      value={value}
      control={<Radio onChange={onChange} checked={isChecked} />}
      label={label}
      sx={{ margin: 'auto' }}
    />
  )
}

const NothingChoice = (props: NothingChoiceProps) => {
  const { onChange, isChecked } = props
  return (
    <NothingControlLabelStyled
      value="Aucun"
      control={<Checkbox onChange={onChange} checked={isChecked} />}
      label="Aucun"
      sx={{ margin: 'auto' }}
    />
  )
}

const formControlStyle = {
  '.MuiFormControlLabel-label': {
    display: 'block',
    color: darkBlue,
    padding: '16px 0px',
    width: '300px',
    backgroundColor: 'white',
    boxShadow: `2px 3px ${customTheme.palette.primary.light}`,
    borderRadius: '16px',
    fontSize: '16px',
    marginBottom: '23px',
  },

  '.Mui-checked + .MuiFormControlLabel-label': {
    backgroundColor: customTheme.palette.secondary.main,
  },

  '.MuiRadio-root': {
    display: 'none',
  },

  '.MuiSvgIcon-root': {
    color: darkBlue,
  },
}

const FormControlLabelStyled = styled(FormControlLabel)({
  ...formControlStyle,
  '.MuiCheckbox-root': {
    position: 'absolute',
    marginLeft: '10px',
    marginBottom: '22px',
  },
})

const NothingControlLabelStyled = styled(FormControlLabel)({
  ...formControlStyle,
  '.MuiCheckbox-root': {
    display: 'none',
  },
})

type SelectElement = {
  value: string
  label: string
}

interface SelectGroupProps {
  elements: SelectElement[]
  multiple: boolean
  dataKey: string
  updateFields: UpdateFieldsType
  selectedValues?: string[]
}

const SelectGroup = (props: SelectGroupProps) => {
  const [selectedCheckboxValues, setSelectedCheckboxValues] = useState<string[]>(props.selectedValues || [])

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target
    let newValues: string[] = []

    if (!checked) {
      newValues = selectedCheckboxValues.filter((prevValue) => prevValue !== value)
    }

    if (checked) {
      if (value === 'Aucun') {
        newValues = ['Aucun']
      } else {
        newValues = [...selectedCheckboxValues.filter((prevValue) => prevValue !== 'Aucun'), value]
      }
    }
    setSelectedCheckboxValues(newValues)
    props.updateFields({ [props.dataKey]: newValues })
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      props.updateFields({ [props.dataKey]: event.target.value })
    }
  }

  return (
    <FormControl sx={{ alignItems: 'center' }}>
      {props.multiple ? (
        <>
          {props.elements.map((element) => (
            <CheckBoxChoice
              value={element.value}
              label={element.label}
              key={element.value}
              onChange={handleCheckboxChange}
              isChecked={selectedCheckboxValues?.includes(element.value)}
            />
          ))}
          <NothingChoice
            onChange={handleCheckboxChange}
            isChecked={selectedCheckboxValues?.includes('Aucun')}
          />
        </>
      ) : (
        <RadioGroup>
          {props.elements.map((element) => (
            <RadioChoice
              value={element.value}
              label={element.label}
              key={element.value}
              onChange={handleRadioChange}
              isChecked={props.selectedValues?.includes(element.value) || false}
            />
          ))}
        </RadioGroup>
      )}
    </FormControl>
  )
}

export default SelectGroup
