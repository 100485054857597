import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import customTheme from '../config/stylesheet'

interface ProgressBarProps {
  step: number
  totalSteps: number
}

export default function ProgressBar(props: ProgressBarProps) {
  const { step, totalSteps } = props
  return (
    <Box sx={{ width: '70%', minWidth: '250px', marginLeft: '10px' }}>
      <LinearProgress
        variant="determinate"
        value={(step / totalSteps) * 100}
        color="primary"
        sx={{ backgroundColor: `${customTheme.palette.primary.light}`, height: '2px' }}
      />
    </Box>
  )
}
