import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Lottie from 'lottie-react'
import progressBar from '../lotties/progress_bar.json'

const ThankYouPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <div style={{ minWidth: '250px', width: '70%', marginLeft: '42px' }}>
        <Lottie animationData={progressBar} />
      </div>

      <div style={{ marginTop: '7vh', zIndex: '1', fontWeight: '400', marginRight: '-30px' }}>
        <Typography
          color="primary"
          fontSize="40px"
          fontFamily="Titan One, cursive"
          fontWeight={400}
          textAlign="left"
        >
          {t('thank-you.title')}
        </Typography>
        <Typography
          color="secondary"
          textAlign="left"
          fontFamily="Open Sans"
          fontWeight={400}
          fontSize="24px"
        >
          {t('thank-you.text')}
        </Typography>
      </div>
      <div
        style={{
          position: 'absolute',
          width: '363px',
          height: '338px',
          right: '0',
          top: '40vh',
          overflow: 'hidden',
        }}
      >
        <img
          src="/assets/Wave.jpg"
          style={{
            width: '100%',
            height: '100%',
            marginRight: '-30vw',
          }}
          alt={'ocean wave'}
        />
      </div>
    </>
  )
}

export default ThankYouPage
