export interface SpotOptionType {
  inputValue?: string
  completeSpot: string
}

const spotList: readonly SpotOptionType[] = [
  { completeSpot: "Hendaye - La grande Plage d'Hendaye" },
  { completeSpot: 'Hendaye - Les deux Jumeaux' },
  { completeSpot: 'Socoa - Baie de Socao' },
  { completeSpot: 'Saint Jean de Luz - Baie de Saint Jean' },
  { completeSpot: 'Saint Jean de Luz - Erromardie' },
  { completeSpot: 'Saint Jean de Luz - Lafitenia' },
  { completeSpot: 'Saint Jean de Luz - Mayarco' },
  { completeSpot: 'Guethary - Cenitz' },
  { completeSpot: 'Guethary - Les Alcyon' },
  { completeSpot: 'Bidart - Parlementia' },
  { completeSpot: 'Bidart - Uhabia' },
  { completeSpot: 'Bidart - Les Embruns' },
  { completeSpot: 'Bidart - Bidart Centre' },
  { completeSpot: 'Bidart - Erretegia' },
  { completeSpot: 'Bidart - Pavillon Royal' },
  { completeSpot: 'Bidart - Ilbarritz' },
  { completeSpot: 'Biarritz - Milady' },
  { completeSpot: 'Biarritz - Marbella' },
  { completeSpot: 'Biarritz - Cote des basques' },
  { completeSpot: 'Biarritz - Port vieux' },
  { completeSpot: 'Biarritz - La grande' },
  { completeSpot: 'Biarritz - Miramar' },
  { completeSpot: "Anglet - Plage de la petite Chambre D'amour" },
  { completeSpot: 'Anglet  - Plage du Club' },
  { completeSpot: 'Anglet - Plage des Sables' },
  { completeSpot: 'Anglet - Plage de Marinella' },
  { completeSpot: 'Anglet - Plage des Corsaires' },
  { completeSpot: 'Anglet - La Madrague' },
  { completeSpot: 'Anglet - Plage de l' },
  { completeSpot: 'Anglet - Plage des Dunes' },
  { completeSpot: 'Anglet - Les Cavaliers' },
  { completeSpot: 'Anglet - La Barre' },
  { completeSpot: 'Tarnos - Plage de la Digue' },
  { completeSpot: 'Tarnos - Plage du métro' },
  { completeSpot: 'Labenne - plage centrale' },
  { completeSpot: 'Capbreton - La piste' },
  { completeSpot: 'Capbreton - Santocha' },
  { completeSpot: 'Capbreton - Le prévent' },
  { completeSpot: 'Hossegor - les culs' },
  { completeSpot: 'Hossegor - La gravière' },
  { completeSpot: 'Hossegor - la nord' },
  { completeSpot: 'Hossegor - la centrale' },
  { completeSpot: 'Hossegor - la sud' },
  { completeSpot: 'Seignosse - les casernes' },
  { completeSpot: 'Seignosse - le penon' },
  { completeSpot: 'Seignosse - les estagnots' },
  { completeSpot: 'Penmarch - La torche' },
  { completeSpot: 'Bretignolles sur mer - La sauzaie' },
  { completeSpot: 'Quiberon - port blanc' },
  { completeSpot: 'Oléron - Vert bois' },
  { completeSpot: 'Oléron - Grand village' },
  { completeSpot: 'Ile de ré - Les grenettes' },
  { completeSpot: 'Ile de ré - Gros jonc' },
  { completeSpot: 'Plouzané - Le petit minou' },
  { completeSpot: 'Le conquet - Les blancs sablons' },
  { completeSpot: 'Saint Gilles Croix de Vie- La grande plage' },
  { completeSpot: 'Longeville-sur-Mer - Bud Bud' },
  { completeSpot: 'Plomeur - La pointe de la Torch' },
  { completeSpot: 'Guidel Plage - Plage de la Falaise' },
  { completeSpot: 'Siouville-Hague - Siouville-Hague' },
]

export default spotList
