import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { styled } from '@mui/material'
import Button from '@mui/material/Button'
import { Box } from '@mui/system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { greyColor } from '../config/stylesheet'

const StyledButton = styled(Button)`
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 56px;
  padding: 8px 16px 8px 24px;
  font-family: 'Open Sans', serif;
  gap: 4px;
  font-size: 18px;
  border: 1px solid ${(props) => props.theme.palette.primary.light};
  box-shadow: 2px 3px 0 ${(props) => props.theme.palette.primary.light};
  border-radius: 60px;
  text-transform: none;
  &:active {
    background-color: ${(props) => props.theme.palette.secondary.main};
  }
  :disabled {
    background-color: ${(props) => props.theme.palette.primary.main};
    border: 1px solid ${(props) => props.theme.palette.primary.main};
    box-shadow: 2px 3px 0 ${greyColor};
  }
`

interface FormButtonProps {
  type: string
  onClick: () => void
  disabled?: boolean
}

const FormButton: React.FC<FormButtonProps> = (props) => {
  const { t } = useTranslation()
  return (
    <Box sx={{ display: 'grid' }}>
      <StyledButton variant="contained" onClick={() => props.onClick()} disabled={props.disabled}>
        {t('button.' + props.type)}
        <ArrowForwardIcon />
      </StyledButton>
    </Box>
  )
}

export default FormButton
