import { ThemeProvider } from '@mui/material/styles'
import './App.css'
import StyledLayout from './components/Layout.style'
import customTheme from './config/stylesheet'

const App = () => {
  return (
    <ThemeProvider theme={customTheme}>
      <div className="App">
        <StyledLayout />
      </div>
    </ThemeProvider>
  )
}

export default App
