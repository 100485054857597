import { createTheme } from '@mui/material/styles'

export const backgroundColor = '#4728f4'
export const greyColor = '#929DAB'

export const darkBlue = '#001D4A'

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
      light: '#000539',
    },
    secondary: {
      main: '#7DF4DF',
    },
  },
})

export default customTheme
